<template>
  <div>
    <div class="head">
      <div class="childImg">
        <img src="../../common/img/girl.png" />
      </div>
    </div>
    <div class="scan">
      <p>请扫描体质检测条形码或输入体质检测编码，绑定您的孩子</p>
    </div>
    <div class="scanBtn">
      <van-button type="info" round @click="scan">扫一扫绑定</van-button>
    </div>
    <div class="input">
      <div style="color: #808080">或</div>
      <div style="margin-top: 20px; color: #5a9cff" @click="show = true">
        输入宝贝编码
      </div>
    </div>
    <van-dialog
      v-model="show"
      title="请输入宝贝编码"
      :show-confirm-button="false"
      close-on-click-overlay
    >
      <div style="padding: 20px">
        <van-field v-model="value" label="" placeholder="请输入宝贝编码" />
      </div>
      <div style="padding: 10px; margin-bottom: 30px">
        <van-button type="info" @click="submit()">提交信息</van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import api from '../../common/js/api';
import {getToken} from '../../common/js/cache';
//saveToken
export default {
  data() {
    return {
      show: false,
      value: '',
    };
  },

  components: {},

  computed: {},

  created() {
    setTimeout(() => {
      // saveToken('eyJhbGciOiJIUzUxMiJ9.eyJ0b2tlblR5cGUiOjEsImV4cCI6MTY0ODgwNzk1MywidXNlcklkIjoib1pGWkM2RGpGaGMwYWp5ZFNNanpkRk5BcmUzVSJ9.QpiQcAO4bN7dFxoX_WUHveIpkM2Oq4ooi_vbRRmmkDsQAeffNTzQcsanQOmUpznpA0_lelZvEG966p1orF-xoQ')
      if (this.$route.query.type !== 1) {
        if (getToken()) this.getStuList();
      }
    }, 1000);
  },
  mounted() {},

  methods: {
    scan() {
      let _this = this;
      wx.ready(function() {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维，默认二者都有
          success: function(res) {
            var result = res.resultStr;
            if (result.includes(',')) {
              result = result.split(',')[1];
            }
            _this.$post(api.bindStu, { no: result, origin: 1 }).then((res) => {
              if (res.success) {
                // _this.$toast(result);
                _this.$toast(res.message);
                _this.getStuList();
              } else {
                _this.$toast(res.message);
              }
            });
          },
        });
      });
    },
    submit() {
      this.$post(api.bindStu, { no: this.value, origin: 1 }).then((res) => {
        if (res.success) {
          this.$toast(res.message);
          this.getStuList();
          this.$router.push('/home');
        } else {
          this.$toast(res.message);
        }
      });
    },
    getStuList() {
      this.$post(api.getStuList, { origin: 1 }).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            window.sessionStorage.setItem(
              'childList',
              JSON.stringify(res.data)
            );
            window.sessionStorage.setItem('childId', res.data[0].id);
            this.$router.push('/home');
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  height: 296px;
  background: url('../../common/img/bg.png') 50%;
  background-size: contain;
  text-align: center;
  color: white;
  .childImg {
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
    img {
      width: 89px;
      height: 89px;
    }
  }
}
.scan {
  p {
    text-align: center;
    padding: 0 68px;
    font-size: 16px;
    color: #666;
    margin-top: 42px;
    font-weight: Medium;
  }
}
.scanBtn {
  padding: 0 57px 0 57px;
  margin: 36px 0 20px 0;
}
/deep/.van-button {
  width: 100%;
}
.input {
  font-size: 16px;
  color: #6495ed;
  text-align: center;
}
/deep/.van-cell {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
}
</style>
